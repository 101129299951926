import {React, useEffect, useState} from "react"
import Navbar from './components/Navbar';
import ArbLogo from './assets/arb-logo.png';
import ZksyncLogo from './assets/zkSync_white-logo.png';
import OpLogo from './assets/op-logo.png';
import MantaLogo from './assets/mantaLogo.png';
import GnoLogo from './assets/gnosis-logo.png';
import BlastLogo from './assets/blastLogo.png';
import './tailwind.css';
import { useNavigate } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useQuery,
  gql,
  createHttpLink,
} from "@apollo/client";

const httpLink = createHttpLink({
  uri: "https://lobbyfi.xyz/graphql", //http://localhost:4000/graphqlhttps://lobbyfi.xyz/graphql
});

const authLink = setContext((_, { headers }) => {
  // Retrieve the API key from an environment variable or other secure storage
  const apiKey = process.env.REACT_APP_GRAPHQL_API_KEY;
  return {
    headers: {
      ...headers,
      'x-api-key': apiKey || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});


const VOTING_POWER_QUERY = gql`
query DelegationSums {
  delegationSums {
    network
    erc20
    sum
  }
}
`;

const DelegationStatus = () => {
  const [daos, setDaos] = useState([]);

  const { loading, error, data } = useQuery(VOTING_POWER_QUERY, {
    client
  });

  useEffect(() => {
    if (data && data.delegationSums) {
      setDaos([
        {
          name: "Arbitrum DAO",
          logo: ArbLogo,
          votingPower: data.delegationSums.find(dao => dao.network === 42161)
        },
        {
          name: "Blast DAO",
          logo: BlastLogo,
          votingPower: data.delegationSums.find(dao => dao.network === 81457)
        },
        {
          name: "ZkSync DAO",
          logo: ZksyncLogo,
          votingPower: data.delegationSums.find(dao => dao.network === 324)
        },
        {
          name: "Optimism DAO",
          logo: OpLogo,
          votingPower: data.delegationSums.find(dao => dao.network === 10)
        },
        {
          name: "Manta DAO",
          logo: MantaLogo,
          votingPower: data.delegationSums.find(dao => dao.network === 169)
        },
      ]);
    }
  }, [data]);
  

  const navigate = useNavigate();

  return (
    <div className="relative bg-black min-h-screen">
      <div className="flex flex-col items-center justify-between w-full relative z-10">
        <Navbar />
        
        <div className="flex flex-col items-center w-full max-w-[1080px] my-16">
          <h1 className="text-4xl font-bold text-[#96fdbf] mb-8" style={{ fontFamily: "var(--body-text-font-family)" }}>
            Current LobbyFi Voting Power
          </h1>

          <div className="flex flex-wrap justify-center">
            {daos.map((dao, index) => (
              <div key={index} className="m-4 p-4 border-2 border-[#96fdbf] rounded-md shadow-lg text-center sm:w-1/2 md:w-1/3 lg:w-1/4" style={{ fontFamily: "var(--body-text-font-family)" }}>
                <img src={dao.logo} alt={dao.name} className="h-16 w-auto shadow-lg rounded-full mx-auto"/>
                <h3 className="text-2xl font-bold text-[#96fdbf] mt-4">{dao.name}</h3>
                <p className="mt-2 text-2xl font-bold text-[#96fdbf]">
                  {loading ? 'Loading...' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((dao.votingPower?.sum || 0) / 10**18)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DelegationStatus;