import ArbTokenABI from "./arbToken.json";
import ZkSyncTokenABI from "./zkToken.json";
import OpTokenABI from "./opToken.json";
import MantaTokenABI from "./mantaToken.json";
import BlastTokenABI from "./blastToken.json";
import ProxyAbi from "./proxyABI.json";
import LobbyFiABI from "./lobbyFiContract.json";
import LobbyFiHosterABI from "./lobbyFiHoster.json";


export const contractsByChainID = {
    [42161]: { // arb
      lobbyFi: {
        HosterAddress: "0x7a45eE0be5C4BdC938A5F00A2AEF393f46502D26",
        HosterAbi: LobbyFiHosterABI,
        ProxyAddress: "0x04baFD4206D386Bbe2EBf6Cc0e9d120712C6AbE8",
        ProxyAbi: ProxyAbi
      },
      tokens: [
        {
          name: "ARB",
          address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
          abi: ArbTokenABI,
        },
      ],
    },
    [324]: { // zkSync
      lobbyFi: {
        HosterAddress: "0x871555052259B14b6BbD1D5a6c9256E01D91B239",
        HosterAbi: LobbyFiHosterABI,
        ProxyAddress: "0xa46e5B8Fec15916d89e265E5F7d10e3CCd8D1D81",
        ProxyAbi: ProxyAbi
      },
      tokens: [
        {
          name: "ZK",
          address: "0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E",
          abi: ZkSyncTokenABI,
        },
      ],
    },
    [421614]: { // arb sepolia
      lobbyFi: {
        HosterAddress: "0x2c03726B75AbF7025F658382adAc4a1eeC813032",
        HosterAbi: LobbyFiHosterABI,
        ProxyAddress: "0xf725f0dC652Fd11fcDd05ef9705d0e31A610d204",
        ProxyAbi: ProxyAbi
      },
      tokens: [
        {
          name: "LFI",
          address: "0xfEC5Bc7EFf38453da50C2211251A5826C908C31f",
          abi: ArbTokenABI,
        },
      ],
    },
    [10]: { // op
        lobbyFi: {
          HosterAddress: "0x7a45eE0be5C4BdC938A5F00A2AEF393f46502D26",
          HosterAbi: LobbyFiHosterABI,
          ProxyAddress: "0x04baFD4206D386Bbe2EBf6Cc0e9d120712C6AbE8",
          ProxyAbi: ProxyAbi
        },
        tokens: [
          {
            name: "OP",
            address: "0x4200000000000000000000000000000000000042",
            abi: OpTokenABI,
          },
        ],
      },
      [81457]: { // blast
        lobbyFi: {
          HosterAddress: "0x7a45eE0be5C4BdC938A5F00A2AEF393f46502D26",
          HosterAbi: LobbyFiHosterABI,
          ProxyAddress: "0xbb2c60e16e0b1c3872564af7676a68fe987ab591",
          ProxyAbi: ProxyAbi
        },
        tokens: [
          {
            name: "BLAST",
            address: "0xb1a5700fa2358173fe465e6ea4ff52e36e88e2ad",
            abi: BlastTokenABI,
          },
        ],
      },
      [169]: { // Manta
        lobbyFi: {
          HosterAddress: "0x7a45eE0be5C4BdC938A5F00A2AEF393f46502D26",
          HosterAbi: LobbyFiHosterABI,
          ProxyAddress: "0x04bafd4206d386bbe2ebf6cc0e9d120712c6abe8",
          ProxyAbi: ProxyAbi
        },
        tokens: [
          {
            name: "MANTA",
            address: "0x95cef13441be50d20ca4558cc0a27b601ac544e5",
            abi: MantaTokenABI,
          },
        ],
      },
  };
  
  export const contractsByName = {
    "ARB": {
      chainId: 42161,
      lobbyFi: {
        HosterAddress: "0x7a45eE0be5C4BdC938A5F00A2AEF393f46502D26",
        HosterAbi: LobbyFiHosterABI,
        ProxyAddress: "0x04baFD4206D386Bbe2EBf6Cc0e9d120712C6AbE8",
        ProxyAbi: ProxyAbi
      },
      token: {
        address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
        abi: ArbTokenABI,
      },
    },
    "ZK": {
      chainId: 324,
      lobbyFi: {
        HosterAddress: "0x871555052259B14b6BbD1D5a6c9256E01D91B239",
        HosterAbi: LobbyFiHosterABI,
        ProxyAddress: "0xa46e5B8Fec15916d89e265E5F7d10e3CCd8D1D81",
        ProxyAbi: ProxyAbi
      },
      token: {
        address: "0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E",
        abi: ZkSyncTokenABI,
      },
    },
    "TEST": {
      chainId: 421614,
      lobbyFi: {
        HosterAddress: "0x2c03726B75AbF7025F658382adAc4a1eeC813032",
        HosterAbi: LobbyFiHosterABI,
        ProxyAddress: "0xf725f0dC652Fd11fcDd05ef9705d0e31A610d204",
        ProxyAbi: ProxyAbi
      },
      token: {
        address: "0xfEC5Bc7EFf38453da50C2211251A5826C908C31f",
        abi: ArbTokenABI,
      },
    },
    "OP": {
      chainId: 10,
      lobbyFi: {
        HosterAddress: "0x7a45eE0be5C4BdC938A5F00A2AEF393f46502D26",
        HosterAbi: LobbyFiHosterABI,
        ProxyAddress: "0x04baFD4206D386Bbe2EBf6Cc0e9d120712C6AbE8",
        ProxyAbi: ProxyAbi
      },
      token: {
        address: "0x4200000000000000000000000000000000000042",
        abi: OpTokenABI,
      },
    },
    "BLAST": {
      chainId: 81457,
      lobbyFi: {
        HosterAddress: "0x7a45eE0be5C4BdC938A5F00A2AEF393f46502D26",
        HosterAbi: LobbyFiHosterABI,
        ProxyAddress: "0xbb2c60e16e0b1c3872564af7676a68fe987ab591",
        ProxyAbi: ProxyAbi
      },
      token: {
        address: "0xb1a5700fa2358173fe465e6ea4ff52e36e88e2ad",
        abi: BlastTokenABI,
      },
    },
    "MANTA": {
      chainId: 169,
      lobbyFi: {
        HosterAddress: "0x7a45eE0be5C4BdC938A5F00A2AEF393f46502D26",
        HosterAbi: LobbyFiHosterABI,
        ProxyAddress: "0x04bafd4206d386bbe2ebf6cc0e9d120712c6abe8",
        ProxyAbi: ProxyAbi
      },
      token: {
        address: "0x95cef13441be50d20ca4558cc0a27b601ac544e5",
        abi: MantaTokenABI,
      },
    },
  };