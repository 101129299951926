import React, { useEffect } from 'react';

const MatrixRain = () => {
  useEffect(() => {
    var c = document.getElementById("matrix-canvas");
    var ctx = c.getContext("2d");

    c.height = window.innerHeight;
    c.width = window.innerWidth;

    // var english = "1001010101110101010101010010101000101011101111010101010110101010101010101110000101";
    // english = english.split("");

    const katakana = 'アァカサタナハマヤャラワガザダバパイィキシチニヒミリヰギジヂビピウゥクスツヌフムユュルグズブヅプエェケセテネヘメレヱゲゼデベペオォコソトノホモヨョロヲゴゾドボポヴッン';
    const latin = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const kirill = 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ';
    const nums = '0123456789';
    const alphabet = kirill + katakana + latin + nums;

    var font_size = c.width > c.height ? 10 : 6; // Smaller font size for smaller screens
    var columns = c.width / font_size;
    var drops = [];
    for (var x = 0; x < columns; x++)
      drops[x] = 1;

    function draw() {
      ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
      ctx.fillRect(0, 0, c.width, c.height);

      // Adjusted color for less brightness
      ctx.fillStyle = "rgba(0, 150, 0, 0.5)"; // Darker and more transparent green
      ctx.font = font_size + "px arial";
      for (var i = 0; i < drops.length; i++) {
        var text = alphabet[Math.floor(Math.random() * alphabet.length)];
        ctx.fillText(text, i * font_size, drops[i] * font_size);

        if (drops[i] * font_size > c.height && Math.random() > 0.975)
          drops[i] = 0;

        drops[i]++;
      }
    }

    var interval = setInterval(draw, 60);

    return () => clearInterval(interval);
  }, []);

  return <canvas id="matrix-canvas" style={{ 
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0
  }}></canvas>;
};

export default MatrixRain;
