import {React, useEffect, useState} from "react"
import { useEthers } from "@usedapp/core";

import LobbyFiLogo from './assets/LobbyFiLogo.png'
import LobbyFiBuilding from './assets/building.png'
import twitter from './assets/twitter.svg';
import telegram from './assets/telegram.svg';
import VectorGrid from './assets/vector_grid.svg';
import WalletButton from './components/WalletButton';
import MatrixRain from './components/MatrixRain';
import TypingTextComponent from './components/TypingText';
import Navbar from './components/Navbar';
import SnapshotLogo from './assets/snapshotlogo.png'; 
import ArbLogo from './assets/arb-logo.png';
import ZksyncLogo from './assets/zkSync_white-logo.png';
import OpLogo from './assets/op-logo.png';
import MantaLogo from './assets/mantaLogo.png';
import GnoLogo from './assets/gnosis-logo.png';
import BlastLogo from './assets/blastLogo.png';
import './tailwind.css';
import MyRewards from './MyRewards';
import { useNavigate } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useQuery,
  gql,
  createHttpLink,
} from "@apollo/client";

const httpLink = createHttpLink({
  uri: "https://lobbyfi.xyz/graphql", //http://localhost:4000/graphqlhttps://lobbyfi.xyz/graphql
});

const authLink = setContext((_, { headers }) => {
  // Retrieve the API key from an environment variable or other secure storage
  const apiKey = process.env.REACT_APP_GRAPHQL_API_KEY;
  return {
    headers: {
      ...headers,
      'x-api-key': apiKey || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});


const VOTING_POWER_QUERY = gql`
query DelegationSums {
  delegationSums {
    network
    erc20
    sum
  }
}
`;

const Home = () => {
  const { activateBrowserWallet, account } = useEthers();
  const [daos, setDaos] = useState([]);

  const { loading, error, data } = useQuery(VOTING_POWER_QUERY, {
    client
  });

  useEffect(() => {
    if (data && data.delegationSums) {
      setDaos([
        {
          name: "Arbitrum DAO",
          logo: ArbLogo,
          votingPower: data.delegationSums.find(dao => dao.network === 42161)
        },
        {
          name: "Blast DAO",
          logo: BlastLogo,
          votingPower: data.delegationSums.find(dao => dao.network === 81457)
        },
        {
          name: "ZkSync DAO",
          logo: ZksyncLogo,
          votingPower: data.delegationSums.find(dao => dao.network === 324)
        },
        {
          name: "Optimism DAO",
          logo: OpLogo,
          votingPower: data.delegationSums.find(dao => dao.network === 10)
        },
        {
          name: "Manta DAO",
          logo: MantaLogo,
          votingPower: data.delegationSums.find(dao => dao.network === 169)
        },
      ]);
    }
  }, [data]);
  
  const handleClick = () => {
    alert("Coming soon"); // Display coming soon message
  };

  const navigate = useNavigate();

  const handleProposalClick = () => {
    navigate('/Proposals');
  };

  const handleDelegateClick = () => {
    navigate('/MyRewards');
  };

  return (
    <div className="relative bg-black min-h-screen">
      <MatrixRain />
      <div className="flex flex-col  items-center justify-between w-full relative z-10">
        <Navbar/>
        <div className="text-xs sm:text-base w-full bg-color-[#96fdbf] font-header text-black text-center py-1 my-3" style={{ fontFamily: "var(--body-text-font-family)", backgroundColor: '#96fdbf' }}>
            Delegating season 1 is live, first $10 M delegations get x5 points. {" "} 
            <a href="https://docs.lobbyfi.xyz/governfi-summer-season-1" target="_blank" rel="noopener noreferrer" className="underline hover:text-green-800">
            [read more]
            </a>
          </div>
        <div className="flex flex-col items-center w-full max-w-[1080px]">
          {/* Image of LobbyFiBuilding */}
          <div className="md:w-[504px] w-full">
            <img className="hidden md:block w-full h-[294px] object-cover" alt="Image of LobbyFiBuilding" src={LobbyFiBuilding} />
          </div>

          <div className="flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:justify-between mt-4 w-full md:w-[720px]">

            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '200px', height: '100px' }}>
                <button onClick={handleDelegateClick} style={{ fontFamily: "var(--body-text-font-family)", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '200px', height: '50px', lineHeight: '50px', borderRadius: '25px', backgroundColor: 'red', border: 'none', color: 'white', fontSize: '1.2em', cursor: 'pointer', transition: 'transform .2s' }} className="hover:scale-105">[delegate now]</button>
            </div>

            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '200px', height: '100px' }}>
                <button onClick={handleProposalClick} style={{ fontFamily: "var(--body-text-font-family)", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '200px', height: '50px', borderRadius: '25px', backgroundColor: 'blue', border: 'none', color: 'white', fontSize: '1.2em', cursor: 'pointer', transition: 'transform .2s' }} className="hover:scale-105">[acquire votes]</button>
            </div>
          </div>
          
          <div className="px-2 sm:px-0  max-w-full">
            <div className="text-center mt-4">
              <div className="text-sm sm:text-4xl font-bold text-[#96fdbf]" style={{ fontFamily: "var(--body-text-font-family)" }}>
              <TypingTextComponent>
                  Democratising lobbyism, on-chain
                </TypingTextComponent>
                <span className="text-sm sm:text-xl block mt-2">
                <TypingTextComponent delay="4s">
                  (but also off-chain <img src={SnapshotLogo} alt="SnapshotLogo" className="inline-block h-6 w-auto" />)
                  </TypingTextComponent>
                </span>
              </div>
              <div className="overflow-x-hidden">
                <p className="px-4 sm:px-0 text-sm sm:text-xl font-bold text-black mt-4 bg-[#96fdbf] p-4 rounded-md shadow-lg max-w-full" style={{ fontFamily: "var(--body-text-font-family)" }}>
                  Riskless APY on governance tokens - earn by delegating.
                </p>
                <p className="px-4 sm:px-0 text-sm sm:text-xl font-bold text-black mt-4 bg-[#96fdbf] p-4 rounded-md shadow-lg max-w-full" style={{ fontFamily: "var(--body-text-font-family)" }}>
                  Acquire voting power. On-demand. Trustlessly.
                </p>
              </div>
            </div>
          </div>

          <div className="text-center mt-16">
            <h2 className="text-2xl sm:text-4xl font-bold text-[#96fdbf]" style={{ fontFamily: "var(--body-text-font-family)" }}>
              Supported DAOs
            </h2>
          </div>

          <div className="flex flex-row items-center justify-center space-x-4 sm:space-x-6 mt-4 max-w-full">
            <img src={ArbLogo} alt="Logo1" className="h-12 sm:h-16 w-auto shadow-lg rounded-full"/>
            <img src={ZksyncLogo} alt="Logo2" className="h-12 sm:h-16 w-auto shadow-lg rounded-full"/>
            <img src={OpLogo} alt="Logo3" className="h-12 sm:h-16 w-auto shadow-lg rounded-full"/>
            <img src={MantaLogo} alt="Logo4" className="h-12 sm:h-16 w-auto shadow-lg rounded-full"/>
            <img src={BlastLogo} alt="Logo5" className="h-12 sm:h-16 w-auto shadow-lg rounded-full"/>
          </div>

          <div className="text-center mt-16">
            <h2 className="text-2xl sm:text-4xl font-bold text-[#96fdbf]" style={{ fontFamily: "var(--body-text-font-family)" }}>
              Current LobbyFi voting power
            </h2>
          </div>

          <div className="flex flex-wrap justify-center">
          {daos.map((dao) => (
            <div className="m-4 p-4 border-2 border-[#96fdbf] rounded-md shadow-lg text-center sm:w-1/2 md:w-1/3 lg:w-1/4" style={{ fontFamily: "var(--body-text-font-family)" }}>
              <img src={dao.logo} alt={dao.name} className="h-12 sm:h-16 w-auto shadow-lg rounded-full mx-auto"/>
              <h3 className="text-xl sm:text-2xl font-bold text-[#96fdbf] mt-4">{dao.name}</h3>
              <p className="mt-2 text-xl sm:text-2xl font-bold text-[#96fdbf]">{loading ? 'Loading...' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((dao.votingPower?.sum || 0) / 10**18)}</p>
            </div>
          ))}
          </div>

          <div className="text-center mt-16">
            <h2 className="text-2xl sm:text-4xl font-bold text-[#96fdbf]" style={{ fontFamily: "var(--body-text-font-family)" }}>
              FAQ
            </h2>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-8 px-4 sm:px-0">
          <div className="p-8 border-2 border-[#96fdbf] text-[#96fdbf] rounded-md shadow-lg" style={{ fontFamily: "var(--body-text-font-family)" }}>
          <a href="https://docs.lobbyfi.xyz/introduction-to-lobbyfi/how-does-it-work" target="_blank" rel="noopener noreferrer">
            <h3 className="text-xl sm:text-2xl font-bold text-[#96fdbf] underline hover:text-[#3DFF54]">How it works</h3>
          </a>
            <p className="mt-2 text-sm sm:text-base">LobbyFi allows users to delegate their voting power in a DAO, which can then be bought by others in auctions or at a fixed price to influence voting decision.</p>
            <a href="https://docs.lobbyfi.xyz/introduction-to-lobbyfi/how-does-it-work"target="_blank" rel="noopener noreferrer" className="text-[#96fdbf] hover:text-[#3DFF54] underline mt-4">[Learn more]</a>
          </div>
            <div className="p-8 border-2 border-[#96fdbf] text-[#96fdbf] rounded-md shadow-lg" style={{ fontFamily: "var(--body-text-font-family)" }}>
              <a href="https://docs.lobbyfi.xyz/introduction-to-lobbyfi/why-delegate-to-lobbyfi" target="_blank" rel="noopener noreferrer">
                <h3 className="text-xl sm:text-2xl font-bold text-[#96fdbf] underline hover:text-[#3DFF54]">Why delegate to Lobbyfi</h3>
              </a>
              <p className="mt-2">Delegate to LobbyFi to earn yield on your governance tokens without giving up control or custody. </p>
              <a href="https://docs.lobbyfi.xyz/introduction-to-lobbyfi/why-delegate-to-lobbyfi"target="_blank" rel="noopener noreferrer"className="text-[#96fdbf] hover:text-[#3DFF54] underline mt-4">[Learn more]</a>
            </div>
            <div className="p-8 border-2 border-[#96fdbf] text-[#96fdbf] rounded-md shadow-lg" style={{ fontFamily: "var(--body-text-font-family)" }}>
            <a href="https://docs.lobbyfi.xyz/introduction-to-lobbyfi/how-does-votes-acquisition-work" target="_blank" rel="noopener noreferrer">
                <h3 className="text-xl sm:text-2xl font-bold text-[#96fdbf] underline hover:text-[#3DFF54]">How to buy votes</h3>
            </a>
              <p className="mt-2">Votes acquisition works through instant buys, where votes are purchased immediately, and auctions, where users bid on votes in favor or against a proposal, with the highest bidding pool determining the outcome.</p>
              <a href="https://docs.lobbyfi.xyz/introduction-to-lobbyfi/how-does-votes-acquisition-work" target="_blank" rel="noopener noreferrer" className="text-[#96fdbf] hover:text-[#3DFF54] underline mt-4">[Learn more]</a>
            </div>
            <div className="p-8 border-2 border-[#96fdbf] text-[#96fdbf] rounded-md shadow-lg" style={{ fontFamily: "var(--body-text-font-family)" }}>
            <a href="https://docs.lobbyfi.xyz/introduction-to-lobbyfi/what-are-the-risks" target="_blank" rel="noopener noreferrer">
                <h3 className="text-xl sm:text-2xl font-bold text-[#96fdbf] underline hover:text-[#3DFF54]">What are the risks</h3>
            </a>
              <p className="mt-2">The risks of delegating to LobbyFi are non-existent as you retain ownership, custody, and liquidity of your tokens, with the option to revoke delegation at any time.</p>
              <a href="https://docs.lobbyfi.xyz/introduction-to-lobbyfi/what-are-the-risks" target="_blank" rel="noopener noreferrer" className="text-[#96fdbf] hover:text-[#3DFF54] underline mt-4">[Learn more]</a>
            </div>
          </div>

        </div>
        {/* Footer */}
        <div className="flex flex-col items-end justify-center w-full mt-16">
          <div className="flex justify-between items-center w-full px-[2px] py-2 bg-black">
            {/* Text */}
            <p className="text-xs sm:text-base font-body-text text-[#96fdbf] tracking-[1.00px] leading-[20px]"
              style={{ fontFamily: 'var(--body-text-font-family)' }}>
              © 2024 LobbyFi. All rights reserved.
            </p>

            {/* Icons */}
            <div className="flex space-x-4">
              <a href="https://t.me/lobbyfichat" target="_blank" rel="noopener noreferrer" className="hover:text-[#3DFF54] text-[#96fdbf]">
                [telegram]
              </a>
              <a href="https://twitter.com/lobbyfinance" target="_blank" rel="noopener noreferrer" className="hover:text-[#3DFF54] text-[#96fdbf]">
                [twitter]
              </a>
            </div>
          </div>

          {/* Grid Component */}
          <div className="w-full  bg-black">
            <img src={VectorGrid} alt="Retro Vector Grid" className="w-full" />
          </div>
        </div>
      </div>
  </div>
  );
}

export default Home;