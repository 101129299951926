import React, { useEffect, useState, useRef } from "react";
import { Connector, useConnect, useAccount, useDisconnect, useEnsAvatar, useEnsName } from 'wagmi';
import { config } from '../config';
import { getChainId, switchChain } from '@wagmi/core'
import ArbLogo from '../assets/arb-logo.png';
import ZksyncLogo from '../assets/zkSyncZlogo.png';
import OpLogo from '../assets/op-logo.png';
import MantaLogo from '../assets/mantaLogo.png';
import GnoLogo from '../assets/gnosis-logo.png';
import BlastLogo from '../assets/blastLogo.png';


const WalletButton = () => {
  const [rendered, setRendered] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const { connectors, connect } = useConnect();
  const dropdownRef = useRef(null);

  const { address } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const chainId = getChainId(config);

  const { disconnect } = useDisconnect()

  const chainIdToLogo = {
    324: ZksyncLogo,
    42161: ArbLogo,
    421614: ArbLogo,
    10: OpLogo,
    169: MantaLogo,
    100: GnoLogo,
    81457: BlastLogo
  };

  const shortenAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  useEffect(() => {
    if (ensName) {
      setRendered(ensName);
    } else if (address) {
      setRendered(shortenAddress(address));
    } else {
      setRendered("");
    }
  }, [address, ensName, setRendered]);

  const handleButtonClick = async () => {
    // You can select a specific connector based on your requirements
    // For example, to use the first available connector:
    const connector = connectors[0];
    if (!address) {
      if (connector) {
        await connect({ connector });
      }
    } else {
      setShowMenu(!showMenu);
    }
  };

  const handleNetworkSwitch = async (newChainId) => {
    if (chainId !== newChainId) {
      await switchChain(config, { chainId: newChainId });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
  
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const dropdownMenuStyle = {
    display: 'block',
    position: 'absolute',
    minWidth: '160px',
    zIndex: 1,
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    backgroundColor: '#f9f9f9',
    padding: '12px 16px',
  };

  const dropdownButtonStyle = {
    color: 'black',
    textDecoration: 'none',
    display: 'block',
    border: 'none',
    background: 'none',
    width: '100%',
    textAlign: 'left',
    padding: '12px 0',
    cursor: 'pointer',
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
     <button
        onClick={(event) => {
          event.stopPropagation();
          handleButtonClick();
        }}
        className={" w-[125px] h-[28px] mt-[-1.00px] font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54]"}
        style={{ fontFamily: "var(--body-text-font-family)", }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {rendered !== "" && rendered && chainId && (
            <img src={chainIdToLogo[chainId]} alt="Chain Logo" width="16" height="16" className="mr-2" />
          )}
          {rendered === "" && "[connect wallet]"}
          {rendered !== "" && rendered}
        </div>
      </button>
    {showMenu && address && (
      <div ref={dropdownRef} style={{ ...dropdownMenuStyle, backgroundColor: 'black', border: '1px solid #96fdbf' }}>
            {chainId !== 421614 && (
              <button 
                  className="flex items-center font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54] border-b-[1px] border-[#96fdbf]" 
                  style={{ fontFamily: "var(--body-text-font-family)", width: '100%' }} 
                  onClick={() => handleNetworkSwitch(421614)}
              >
                  <img src={ArbLogo} alt="Arbitrum Logo" width="16" height="16" className="mr-2" /> Switch to Arbitrum Sepolia
              </button>
            )}
            {chainId !== 42161 && (
              <button 
                  className="flex items-center font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54] border-b-[1px] border-[#96fdbf]" 
                  style={{ fontFamily: "var(--body-text-font-family)", width: '100%' }} 
                  onClick={() => handleNetworkSwitch(42161)}
              >
                  <img src={ArbLogo} alt="Arbitrum Logo" width="16" height="16" className="mr-2" /> Switch to Arbitrum
              </button>
            )}
            {chainId !== 324 && (
              <button 
                  className="flex items-center font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54] border-b-[1px] border-[#96fdbf]" 
                  style={{ fontFamily: "var(--body-text-font-family)", width: '100%' }} 
                  onClick={() => handleNetworkSwitch(324)}
              >
                  <img src={ZksyncLogo} alt="zkSync Logo" width="16" height="16" className="mr-2" /> Switch to zkSync
              </button>
            )}
             {chainId !== 169 && (
              <button 
                  className="flex items-center font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54] border-b-[1px] border-[#96fdbf]" 
                  style={{ fontFamily: "var(--body-text-font-family)", width: '100%' }} 
                  onClick={() => handleNetworkSwitch(169)}
              >
                  <img src={MantaLogo} alt="Manta Logo" width="16" height="16" className="mr-2" /> Switch to Manta Pacific
              </button>
            )}
             {chainId !== 81457 && (
              <button 
                  className="flex items-center font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54] border-b-[1px] border-[#96fdbf]" 
                  style={{ fontFamily: "var(--body-text-font-family)", width: '100%' }} 
                  onClick={() => handleNetworkSwitch(81457)}
              >
                  <img src={BlastLogo} alt="Blast Logo" width="16" height="16" className="mr-2" /> Switch to Blast
              </button>
            )}
            {chainId !== 10 && (
              <button 
                  className="flex items-center font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54] border-b-[1px] border-[#96fdbf]" 
                  style={{ fontFamily: "var(--body-text-font-family)", width: '100%' }} 
                  onClick={() => handleNetworkSwitch(10)}
              >
                  <img src={OpLogo} alt="Optimism Logo" width="16" height="16" className="mr-2" /> Switch to Optimism
              </button>
            )}
            {/* {chainId !== 100 && (
              <button 
                  className="flex items-center font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54] border-b-[1px] border-[#96fdbf]" 
                  style={{ fontFamily: "var(--body-text-font-family)", width: '100%' }} 
                  onClick={() => handleNetworkSwitch(100)}
              >
                  <img src={GnoLogo} alt="Gnosis Logo" width="16" height="16" className="mr-2" /> Switch to Gnosis
              </button>
            )} */}
              <button className="p-4 font-header font-[number:var(--header-font-weight)] text-[#96fdbf] text-[length:var(--header-font-size)] tracking-[var(--header-letter-spacing)] leading-[var(--header-line-height)] whitespace-nowrap [font-style:var(--header-font-style)] hover:text-[#3DFF54] border-b-[1px] border-[#96fdbf]" style={{ fontFamily: "var(--body-text-font-family)", width: '100%' }} onClick={disconnect}>Disconnect</button>
            </div>
          )}
    </div>
);
};

export default WalletButton;